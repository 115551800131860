import {NextRouter} from 'next/router';
import queryString from 'query-string';

const PARAM_REG_EXP = /:([\w-]+)/g;
const OPTIONAL_PARAM_REG_EXP = /\/:([\w-]+)\?\/|\/:([\w-]+)\?$/g;

export type TParams = {
  [key: string]: string | number | boolean | null | undefined;
};

// Create URL from url constant
// @param {string} urlConstant Url string in format /some/path/:with/:param
// @param {object} params Url parameters
// @param {object} query Query string parameters (optional)
export const makeUrl = (urlConstant: string, params: TParams = {}, query?: TParams) => {
  let path = urlConstant;
  const optionalParamNames = urlConstant.match(OPTIONAL_PARAM_REG_EXP);
  const paramsNames = urlConstant.replace(OPTIONAL_PARAM_REG_EXP, '').match(PARAM_REG_EXP);

  if (optionalParamNames) {
    optionalParamNames.forEach(function (optionalName) {
      const key = optionalName.slice(2, -1);
      if (!(key in params) || !params[key]) {
        path = path.replace(optionalName.endsWith('/') ? optionalName.slice(0, -1) : optionalName, '');
      } else {
        path = path.replace(optionalName.slice(1), encodeURIComponent(params[key] ?? ''));
      }
      return path;
    });
  }

  if (paramsNames) {
    paramsNames.forEach(function (name) {
      const key = name.slice(1);
      if (!(key in params)) {
        throw new Error(`Not found \`${key}\` parameter for ${urlConstant} url.`);
      }
      return (path = path.replace(name, encodeURIComponent(params[key] ?? '')));
    });
  }

  if (query && queryString.stringify(query)) {
    path += `?${queryString.stringify(query)}`;
  }

  return path;
};

export const changeQueryParams = (router: NextRouter, queryParams: TParams, replace = false) => {
  router[replace ? 'replace' : 'push'](
    makeUrl(
      window.location.pathname,
      {},
      {
        ...getQueryParamsWithoutDuplicates(window.location.search),
        ...queryParams,
      },
    ),
  );
};

type TParsedQueryWithoutDuplicates = {[key: string]: string};

/**
 * Wrapper around queryString.parse that throws out duplicate query params.
 * It will only use the first value for that query param.
 */
export function getQueryParamsWithoutDuplicates(search: string): TParsedQueryWithoutDuplicates {
  const queryParams = queryString.parse(search);
  const keys = Object.keys(queryParams);

  return keys.reduce((acc: TParsedQueryWithoutDuplicates, key) => {
    const value = queryParams[key];
    if (typeof value === 'string') {
      acc[key] = value;
    } else if (value && typeof value === 'object' && value[0] !== null) {
      acc[key] = value[0];
    }
    return acc;
  }, {} as TParsedQueryWithoutDuplicates);
}

export const getPathnameFromURL = (url: string) => {
  try {
    const {pathname} = new URL(url);
    return pathname;
  } catch (error) {
    return null;
  }
};
