import {AxiosError, isAxiosError} from 'axios';

export type TRequestError = {
  requestId: string;
  humanReadableMessage: string;
  message: string;
  status: number;
  code: string;
  summary: string;
};

export const requestErrorBuilder = function (err: unknown): TRequestError {
  // Defaults for error message
  const error: TRequestError = {
    requestId: '',
    humanReadableMessage: '',
    message: '',
    status: 0, // Unknown status code is 0 instead of 200
    code: '',
    summary: '',
  };

  if (isAxiosError(err)) {
    error.code = err.code as string;
    error.summary = `${err.message}`;
    error.status = err.request.status;
    if (err.response) {
      const data = err.response.data;
      error.requestId = data.request_id || '';
      error.humanReadableMessage = data.human_readable_message || '';
      error.message = data.message || data.text;
    }
  } else {
    error.message = JSON.stringify(err);
  }
  return error;
};

// This function is responsible for filtering 404 and 5xx errors
export const isRequestErrorReportable = (err: TRequestError): boolean =>
  new RegExp(/(?:404|5\d{2})/).test(err.status.toString().trim());

export class RequestError extends Error {
  error: TRequestError;

  constructor(name: string, error: TRequestError) {
    super(`${name} | ${error.summary} ${error.message ? ` | Cause: ${error.message}` : ''}`);
    this.error = error;
  }
}

export class ExtendedAxiosError extends AxiosError {
  requestError: TRequestError;
  isRequestErrorReported: boolean;

  constructor(err: AxiosError, requestError: TRequestError, isRequestErrorReported: boolean) {
    super(err?.message, err?.code, err?.config, err?.request, err?.response);
    this.requestError = requestError;
    this.isRequestErrorReported = isRequestErrorReported;
  }
}
