import {SpanStatusCode, trace} from '@opentelemetry/api';
import {AxiosError} from 'axios';
import {NextPage} from 'next';
import App, {AppContext, AppInitialProps} from 'next/app';
import {TGlobalVariables} from 'shared/platform/window';
import {getBamPermissions, getFeatureFlags, getPartner, getRoles, getUser, isTermsOfServiceSigned} from 'shared/server';

type TNextServerPageConfig = {
  title: string | ((globals: TGlobalVariables) => string);
  /**
   * Allow unauthenticated access to this page. Note: Public pages don't support permission and feature gate checks.
   */
  allowUnauthenticated?: boolean;
  /**
   * A list of permissions that the user must have in order to access the page.
   */
  requiredPermissions?: string[];
  /**
   * A list of feature gates that must be on to render the page.
   */
  requiredFeatureGates?: string[];
  /**
   * Whether the page requires the Terms of Service to be signed.
   */
  requiresTermsOfService?: boolean;
  /**
   * The navigation section and subsection for this page.
   */
  navigation?: {
    section: string;
    subsection: string;
  };
  /**
   * Use this hardcoding the partner for (internal) pages that don't have a namespace in the URL
   * e.g. needed for the prebuilt template editor, when publishing the user is taken to a 404 page, per https://bluecore.atlassian.net/browse/PROD-65592
   */
  partner?: string;
};

export type TNextServerPage<Props = {}, InitialProps = Props> = NextPage<
  Props & {globals: TGlobalVariables},
  InitialProps
> & {
  config?: TNextServerPageConfig;
};

export const fetchInitialData = async ({
  partner,
  isSessionValid,
  appContext,
  jwt,
}: {
  partner: string;
  isSessionValid: boolean;
  appContext: AppContext;
  jwt: string;
}) => {
  return await trace.getTracer('fetchInitialDataTracer').startActiveSpan('fetchInitialData', async (parentSpan) => {
    try {
      const [bamPermissions, featureGates, user, partnerInfo, isTosSigned, roles, initialProps] = await Promise.all([
        isSessionValid ? getBamPermissions(partner, jwt) : ([] as string[]),
        isSessionValid ? getFeatureFlags(partner, jwt) : ([] as string[]),
        isSessionValid ? getUser(jwt) : undefined,
        partner && jwt ? getPartner(partner, jwt) : undefined,
        partner && jwt ? isTermsOfServiceSigned(partner, jwt) : false,
        isSessionValid && partner ? getRoles(partner, jwt) : ([] as string[]),
        App.getInitialProps(appContext),
      ]);

      return {
        bamPermissions,
        featureGates,
        user,
        partnerInfo,
        roles,
        isTosSigned,
        initialProps,
      };
    } catch (error) {
      trace.getActiveSpan()?.recordException(error as Error);
      trace.getActiveSpan()?.setStatus({code: SpanStatusCode.ERROR});
      if ((error as AxiosError).response) {
        trace.getActiveSpan()?.setAttribute('response.body', (error as AxiosError).response?.data as string);
      }

      return {
        bamPermissions: [] as string[],
        featureGates: [] as string[],
        user: undefined,
        partnerInfo: undefined,
        roles: [] as string[],
        isTosSigned: false,
        initialProps: {} as AppInitialProps,
        error,
      };
    } finally {
      parentSpan.end();
    }
  });
};
