import {TAppProps} from 'pages/_app';
import {CONFIG} from 'shared/platform/config';
import {TPartner, TUser} from 'shared/server';

export type TGlobalVariables = {
  // These are the same data.
  // We leave `permissions` around for legacy code.
  // Use bamPermissions going forward.
  bamPermissions: Set<string>;
  permissions: Set<string>;

  // These are the same data.
  // We leave `enabled_features` around for legacy code.
  // Use featureGates going forward.
  featureGates: Set<string>;
  enabled_features: Set<string>;

  user?: TUser;
  partnerInfo?: TPartner;
  roles: string[];
  isTosSigned: boolean;

  partner?: string;
  env: string;
  monolithUrl: string;

  // These are used to highlight the active navigation item
  navigationOpen?: string;
  navigationActive?: string;
};

declare global {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface Window extends TGlobalVariables {}
}

type TGlobalKeys = keyof TGlobalVariables;

export const getGlobals = ({
  bamPermissions,
  featureGates,
  user,
  partnerInfo,
  roles,
  isTosSigned,
  Component,
}: Pick<
  TAppProps,
  'bamPermissions' | 'featureGates' | 'user' | 'partnerInfo' | 'roles' | 'isTosSigned' | 'Component'
>) => {
  const bamPermissionsSet = new Set(bamPermissions);
  const featureGatesSet = new Set<string>(featureGates);

  return {
    // We support both of these to support legacy code.
    // Use bamPermissions going forward.
    bamPermissions: bamPermissionsSet,
    permissions: bamPermissionsSet,

    // We support both of these to support legacy code.
    // Use featureGates going forward.
    enabled_features: featureGatesSet,
    featureGates: featureGatesSet,

    user,
    partnerInfo,
    roles,
    isTosSigned,

    partner: partnerInfo?.name,
    env: CONFIG.env,
    monolithUrl: CONFIG.monolith_url,

    // These are used to highlight the active navigation item
    navigationOpen: Component.config?.navigation?.section,
    navigationActive: Component.config?.navigation?.subsection,

    // These are required for Segment
    userId: user?.email,
    user_email: user?.email,
    traits: user && {
      email: user.email,
      firstName: user.first_name,
      lastName: user.last_name,
      internal: user.internal,
      partner: partnerInfo?.name,
    },
    traitsV2: user && {
      $email: user.email,
      $first_name: user.first_name,
      $last_name: user.last_name,
      bluecore_employee: user.internal,
      namespace_email: partnerInfo && user.email,
      account_name: partnerInfo?.parent_namespace,
      namespace: partnerInfo?.name,
      namespace_status: partnerInfo?.status,
    },

    // These are required for Intercom and Fullstory
    userEmail: user?.email,
    userFirstName: user?.first_name,
    userLastName: user?.last_name,
    userCreatedDate: user?.created,
    partnerCreatedDate: partnerInfo?.created,
  };
};

export const setWindowVariables = (globals: TGlobalVariables) => {
  const keys: TGlobalKeys[] = Object.keys(globals) as TGlobalKeys[];
  keys.forEach((key) => {
    (window[key] as unknown) = globals[key];
  });
};
