import {JWT} from 'next-auth/jwt';

import {CONFIG} from './config';

/**
 * Takes a token, and returns a new token with updated
 * `accessToken` and `accessTokenExpires`. If an error occurs,
 * returns the old token and an error property
 */
export const refreshAccessToken = async (token: JWT) => {
  try {
    const urlBase = `${CONFIG.okta_issuer}/oauth2/v1/token?`;

    const params = new URLSearchParams({
      client_id: CONFIG.okta_clientid,
      client_secret: process.env.OKTA_CLIENTSECRET || '',
      grant_type: 'refresh_token',
      refresh_token: `${token.refreshToken}`,
    });

    const url = urlBase + params;

    const response = await fetch(url, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      method: 'POST',
    });

    const refreshedTokens = await response.json();
    if (!response.ok) {
      throw refreshedTokens;
    }

    return {
      ...token,
      rawJWT: refreshedTokens.id_token,
      accessToken: refreshedTokens.access_token,
      accessTokenExpires: Date.now() + refreshedTokens.expires_in * 1000,
      refreshToken: refreshedTokens.refresh_token ?? token.refreshToken, // Fall back to old refresh token
    };
  } catch (error) {
    return {
      ...token,
      error: 'RefreshAccessTokenError',
    };
  }
};
