/***
 * Deduplicates an async function, returning the currently pending Promise if exists instead of invoking the function.
 */
export const deduplicateAsync = <T>(fn: () => Promise<T>): (() => Promise<T>) => {
  let currentPromise: Promise<T> | null = null;

  return () => {
    if (currentPromise) return currentPromise;
    currentPromise = fn();
    return currentPromise.finally(() => {
      currentPromise = null;
    });
  };
};
