import React from 'react';

import {Flex} from '@triggermail/bluecore-ui-components';

import {TTIMarker} from '../TTI';

import styles from './unauthorized.module.scss';

export const Unauthorized: React.FC = () => (
  <Flex className={styles.container} space={6} direction="column" justify="center" align="center">
    <h3>Sorry! You do not have permission to access this page.</h3>
    <img src="/lock.svg" />
    <h3>Please contact your account administrator to request access.</h3>
    <TTIMarker />
  </Flex>
);
