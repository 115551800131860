import {datadogLogs} from '@datadog/browser-logs';
import {datadogRum} from '@datadog/browser-rum';
import {getPathnameFromURL} from 'shared/frontend-utils/url_utils';
import {CONFIG, ENV, VERSION} from 'shared/platform/config';
import {matchPath} from 'shared/platform/match_path';
import {TUser} from 'shared/server';

const IGNORED_LOGS = [
  'Deprecation warning: value provided is not in a recognized RFC2822 or ISO format.',
  'Partner Allowlist',
  'Partner Blocklist',
  'Global Allowlist',
];

export const initDataDog = () => {
  datadogRum.init({
    applicationId: CONFIG.DD_APPLICATION_ID,
    allowedTracingUrls: [
      {match: CONFIG.bluecore_api_url, propagatorTypes: ['b3multi']},
      {match: CONFIG.monolith_url, propagatorTypes: ['b3multi']},
      {match: CONFIG.nextserver_url, propagatorTypes: ['b3multi']},
    ],
    clientToken: CONFIG.DD_CLIENT_TOKEN,
    defaultPrivacyLevel: 'mask-user-input',
    env: ENV,
    service: CONFIG.DD_SERVICE_NAME,
    site: 'us5.datadoghq.com',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    version: VERSION,
    enableExperimentalFeatures: ['feature_flags'],
    beforeSend: (event) => {
      const pathname = getPathnameFromURL(event.view.url);
      const path = pathname && matchPath(pathname);

      if (path) {
        event.view.name = path.path;

        if (path.params.partner) {
          datadogRum.setGlobalContextProperty('namespace', path.params.partner);
        } else {
          datadogRum.removeGlobalContextProperty('namespace');
        }
      }

      // Removes console errors which contains bypass string
      if (
        event.type === 'error' &&
        event.error.source === 'console' &&
        event.error.message.includes(CONFIG.DD_ERROR_LOG_BYPASS)
      ) {
        return false;
      }

      return true;
    },
  });

  datadogLogs.init({
    clientToken: CONFIG.DD_CLIENT_TOKEN,
    site: 'us5.datadoghq.com',
    forwardErrorsToLogs: true,
    sessionSampleRate: 100,
    forwardConsoleLogs: 'all',
    beforeSend: (log) => !IGNORED_LOGS.some((message) => log.message.includes(message)),
  });
};

export const setDataDogUser = (user: TUser) => {
  datadogRum.setUser({email: user.email});
};
