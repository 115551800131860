import {AxiosError} from 'axios';

const isAxiosError = (error: unknown): error is AxiosError => (error as AxiosError).response != null;

export const stringifyError = (error: unknown) => {
  if (isAxiosError(error)) {
    return JSON.stringify({
      url: `${error.response?.config.baseURL || ''}${error.response?.config.url}`,
      status: error.response?.status,
      data: error.response?.data,
    });
  }

  if (error instanceof Error) {
    return error.message || error.toString();
  }

  return String(error);
};
