import {datadogRum} from '@datadog/browser-rum';

import {ExtendedAxiosError} from './request_error';

export const captureError = (err: unknown, info?: object) => {
  // The errors which were already reported at the interceptor layer
  if (err instanceof ExtendedAxiosError && err.isRequestErrorReported) {
    return;
  }

  datadogRum.addError(err, info);
};
