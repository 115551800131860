import {HttpStatusCode} from 'axios';
import cookie from 'cookie';
import {GetServerSidePropsContext, NextApiResponse, NextPageContext} from 'next';
import queryString from 'query-string';
import {isClient} from 'shared/server/is_client';

import {IS_NEXTAUTH_SECURE, NEXTAUTH_COOKIE_PREFIX} from './config';

/**
 * Takes a list of permissions a user has and a list of permissions a user needs
 * and returns if the user has all the permissions they need.
 */
export const hasAllPermissions = (permissions: Set<string>, requiredPermissions?: string[]): boolean => {
  if (!requiredPermissions) {
    return true;
  }
  return requiredPermissions.every((permission) => permissions.has(permission));
};

/**
 * Handles redirecting to the login page if not logged in.
 */
export function redirectToLogin(ctx: NextPageContext) {
  if (ctx.res) {
    ctx.res
      .writeHead(HttpStatusCode.Found, {Location: `/login?${queryString.stringify({callbackUrl: ctx.req?.url})}`})
      .end();
  }

  if (isClient()) {
    window.location.href = `/login?${queryString.stringify({
      callbackUrl: window.location.pathname + window.location.search,
    })}`;
  }
}

export function redirectToTermsOfService(ctx: NextPageContext, partner: string) {
  if (ctx.res) {
    ctx.res.writeHead(HttpStatusCode.Found, {Location: `/admin/tos/${partner}`}).end();
  }
  if (isClient()) {
    window.location.href = `/admin/tos/${partner}`;
  }
}

export function redirectToRoot(ctx: NextPageContext) {
  if (ctx.res) {
    ctx.res.writeHead(HttpStatusCode.Found, {Location: '/'}).end();
  }
  if (isClient()) {
    window.location.href = '/';
  }
}

export const setNextAuthCookies = ({
  res,
  cookies,
  maxAge,
}: {
  res: GetServerSidePropsContext['res'] | NextApiResponse;
  cookies: {key: string; value: string}[];
  maxAge?: number;
}) => {
  res.setHeader(
    'Set-Cookie',
    cookies.map(({key, value}) =>
      cookie.serialize(`${NEXTAUTH_COOKIE_PREFIX}${key}`, value, {
        maxAge,
        httpOnly: true,
        sameSite: 'lax',
        path: '/',
        secure: IS_NEXTAUTH_SECURE,
      }),
    ),
  );
};
