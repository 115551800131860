import {TRequestOptions as TBaseRequestOptions} from '@triggermail/bluecore-api-lib';
import axios, {AxiosError, AxiosRequestConfig, AxiosRequestTransformer, AxiosResponse} from 'axios';
import {addNotification} from 'shared/components/Notification/utils';
import {STATUS_ERROR_MESSAGES} from 'shared/constants/error_messages';
import {ApiError, TErrorResponse, TParsedError} from 'shared/frontend-utils/api_error';
import {ExtendedAxiosError, requestErrorBuilder, TRequestError} from 'shared/frontend-utils/request_error';
import {CONFIG} from 'shared/platform/config';

import {makeUrl, TParams} from '../url_utils';

type TError = {
  stack: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  response?: Record<string, any>;
  statusText: string;
};

type TResult = {
  status: number;
  message: string;
  body: {
    message: string;
    text: string;
    human_readable_message?: string;
    request_id?: string;
  };
  text?: string;
};

export const client = axios.create({baseURL: '', headers: {}});

/**
 * @TODO move this types with this file to bluecore-api-lib
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type TResolve = (response: (string | Record<string, any>) | null | undefined) => void;

export type TReject = (error: TError | ApiError | AxiosError, result?: TResult) => void;

export type TRequestOptions = TBaseRequestOptions & {
  onError?: (response: TErrorResponse) => void;
  errorParser?: (response: TErrorResponse) => TParsedError;
  transformRequest?: AxiosRequestTransformer[];
};

const encodeFormData = <T>(data?: T) =>
  Object.entries(data || {}).reduce((result, [key, val]) => {
    return {
      ...result,
      // encode null values as null string
      [key]: val === null ? 'null' : val,
    };
  }, {});

const getDefaultParams = function (params: TRequestOptions): TRequestOptions {
  const result: TRequestOptions = {
    method: 'get',
    contentType: 'form',
    showErrorNotification: true,
    ...params,
  };

  if (result.method === 'del') {
    result.method = 'delete';
  }

  if (result.urlConstant) {
    const {urlConstant, urlParams, urlQuery} = result;
    result.url = makeUrl(urlConstant, urlParams as TParams, urlQuery);
  }

  if (result.url?.startsWith('/') && !params.routeToPy3Monolith) {
    result.url = `${window.monolithUrl}${result.url}`;
  }

  return result;
};

const loadRequestParams = function (req: AxiosRequestConfig, params: TRequestOptions): void {
  const {data, attachments, contentType, method, routeToPy3Monolith} = params;

  if (method === 'get' && data) {
    req.params = data;
  } else if (contentType === 'form' && data) {
    req.data = encodeFormData(data);
    req.headers = {
      ...req.headers,
      'Content-Type': 'application/x-www-form-urlencoded',
    };
  } else {
    req.data = data;
  }

  if (params.headers) {
    req.headers = {...req.headers, ...params.headers};
  }

  if (attachments?.length) {
    const formData = new FormData();

    Object.entries(data || {}).forEach(([key, val]) => {
      if (val) formData.append(key, String(val));
    });

    attachments.forEach(function (attachment) {
      const {file} = attachment;
      formData.append(attachment.name, file, file.name);
    });

    req.data = formData;
  }

  if (routeToPy3Monolith) {
    req.baseURL = CONFIG.py3_monolith_url;
  }
};

export const onError = function (error: TRequestError, params: TRequestOptions): void {
  const {showAPIErrorNotification, showErrorNotification} = params;

  console.error(
    `API Error Status Code: ${error.status} | API Error Message: ${error.message} | ${CONFIG.DD_ERROR_LOG_BYPASS}`,
  );

  // TODO: remove showAPIErrorNotification and leave only showErrorNotification
  // use humanReadableMessage for customizing the message shown to the user
  if (showAPIErrorNotification) {
    addNotification({
      message: error.humanReadableMessage || error.message,
      type: 'error',
      timeout: 10000,
    });
  }

  if (STATUS_ERROR_MESSAGES[error.status as unknown as keyof typeof STATUS_ERROR_MESSAGES] && showErrorNotification) {
    addNotification({
      message:
        error.humanReadableMessage ||
        STATUS_ERROR_MESSAGES[error.status as unknown as keyof typeof STATUS_ERROR_MESSAGES],
      type: 'error',
      timeout: 10000,
    });
  }
};

const onSuccess = function (params: TRequestOptions, res: AxiosResponse): void {
  if (params.successNotification) {
    const message: string =
      typeof params.successNotification === 'function'
        ? params.successNotification(res.data)
        : params.successNotification;

    return addNotification({
      message,
      type: params.method === 'delete' ? 'info' : 'success',
    });
  }
};

// Performs API server request
// @param {object} params request params
// @option params {string} url
// @option params {string} urlConstant
// @option params {object} urlParams urlConstant and urlParams to pass
// into makeUrl
// @option params {string} [method='get']
// @option params {object} data Request data. Will be used as query
// for GET request
// @option params {string} [contentType='application/json']
// @return {Promise}
export const request = (params: TRequestOptions) => {
  params = getDefaultParams(params);
  const {url, method, transformRequest} = params;

  const config: AxiosRequestConfig = {method, url, transformRequest};

  loadRequestParams(config, params);

  return client(config)
    .then((response) => {
      onSuccess(params, response);
      return response.data;
    })
    .catch((err) => {
      if (!params.ignoreError?.(err)) {
        // display the error to the customer
        err instanceof ExtendedAxiosError && err.isRequestErrorReported
          ? onError(err.requestError, params)
          : onError(requestErrorBuilder(err), params);
      }
      // throw the error forward
      throw err;
    });
};
