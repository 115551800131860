import React from 'react';
import Head from 'next/head';
import {TGlobalVariables} from 'shared/platform/window';

import '@triggermail/bluecore-ui-components/styles.css';

type TProps = {
  title?: string | ((globals: TGlobalVariables) => string);
  partner?: string;
  globals: TGlobalVariables;
};

export const PageHead: React.FC<TProps> = ({title, globals}) => {
  const pageTitle = typeof title === 'function' ? title(globals) : title;

  return (
    <Head>
      <title>{pageTitle ? `${pageTitle} - Bluecore` : 'Bluecore'}</title>
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    </Head>
  );
};
