import {TUser} from 'shared/server';

import {initDataDog, setDataDogUser} from './datadog';

export const initTelemetry = () => {
  initDataDog();
};

export const setTelemetryUser = (user: TUser) => {
  setDataDogUser(user);
};
