import React from 'react';

import {Link} from '@triggermail/bluecore-ui-components';

const ContactSupportLink = () => <Link href="mailto:support@bluecore.com">support@bluecore.com</Link>;

export const STATUS_ERROR_MESSAGES = {
  '400': (
    <span>
      Bad Request: Oops, We’re sorry this request is invalid. Please contact <ContactSupportLink /> if you need further
      assistance.
    </span>
  ),
  '401': (
    <span>
      Unauthorized: You do not have permission for this resource. Please contact your Customer Success Manager to
      request access.
    </span>
  ),
  '403': (
    <span>
      Unauthorized: You do not have permission for this resource. Please contact your Customer Success Manager to
      request access.
    </span>
  ),
  '404': (
    <span>
      Resource Not Found: Oops! We’re having trouble with this request right now. Please contact <ContactSupportLink />{' '}
      if you need further assistance.
    </span>
  ),
  '500': (
    <span>
      Internal: Oops, We’re sorry! Something went wrong and the Bluecore Team will automatically be notified. Please
      contact <ContactSupportLink /> if you need further assistance.
    </span>
  ),
};
