import React, {useEffect} from 'react';
import {datadogRum} from '@datadog/browser-rum';
import {useRouter} from 'next/router';

type TProps = {
  enabled?: boolean;
};

let lastMarkedRoute = '';

/**
 * Sends TTI marker to DataDog once per page view.
 */
export const useMarkTTI = ({enabled = true}: TProps = {enabled: true}) => {
  const {asPath} = useRouter();
  const pathname = asPath?.split('?')[0];

  useEffect(() => {
    if (!enabled || lastMarkedRoute === pathname) return;
    datadogRum.addTiming('bc_time_to_interactive_timing');
    lastMarkedRoute = pathname;
  }, [enabled, pathname]);
};

/**
 * This component marks time to interactive in Datadog.
 * Each route has it's own definition of TTI at this page.
 * https://bluecore.atlassian.net/wiki/spaces/Frontend/pages/2696937574/Per+Page+TTI+Definitions
 *
 * TTIMarker should always be rendered after any blocking API requests have finished.
 * Ideally it will be the last component rendered on the page, so we can enforce the page has
 * been fully rendered before marking TTI.
 * However as long as it's rendered after the main content that's okay.
 */
export const TTIMarker: React.FC<TProps> = ({enabled}) => {
  useMarkTTI({enabled});
  return null;
};

export function withTTIMarker<T extends {}>(config?: TProps | ((props: T) => TProps)) {
  return (Component: React.ComponentType<T>) => {
    const displayName = Component.displayName || Component.name || 'Component';

    const ComponentWithTTIMarker = (props: T) => {
      useMarkTTI(typeof config === 'function' ? config(props) : config);
      return <Component {...props} />;
    };

    ComponentWithTTIMarker.displayName = `withTTIMarker(${displayName})`;

    return ComponentWithTTIMarker;
  };
}
