import sortBy from 'lodash/sortBy';
import {match} from 'path-to-regexp';

import {PATHS} from '../generated/paths';

/***
 * Matches a pathname to one of the pages.
 * Returns the matched path pattern and the path parameters.
 * e.g. `matchPath('/admin/campaigns/automated/qa_bluecore_1')`
 * returns `{path: '/admin/campaigns/automated/:partner', params: {partner: 'qa_bluecore_1'}}`
 */
export const matchPath = (path: string) => {
  const matchedPaths = PATHS.filter((pattern) => match(pattern)(path));

  const matchedPath = sortBy(
    matchedPaths,
    (path) => -path.split('/').length,
    (path) => (path.includes('*') ? 1 : 0),
    (pattern) => {
      const matchResult = match(pattern)(path);
      return matchResult ? Object.keys(matchResult.params).length : Infinity;
    },
  )[0];

  if (!matchedPath) return null;

  const matchResult = match(matchedPath)(path);

  if (!matchResult) return null;

  return {
    path: matchedPath,
    params: matchResult.params as {[key: string]: string},
  };
};
